import React from "react";

import {
  Container,
  Row,
  TextSimpleMoyenBis,
  Title,
  RootHome,
  BoxRadius,
  Sign,
} from "../../Globalstyles";
import FooterComponent from "../Footer";

import icon404 from "../../images/404.svg";
import sadFace from "../../images/Sad.svg";
import Logo from "../../images/logo-babilou.png";

export default function WelcomeComponent() {
  return (
    <>
      <RootHome>
        <Container style={{ padding: "10px" }}>
          <Row>
            <img
              src={Logo}
              style={{
                width: "160px",
                minWidth: "100px",
                margin: "50px",
              }}
              alt="logo"
            />
          </Row>
          <Row>
            <BoxRadius style={{ backgroundImage: `url(${sadFace})` }}>
              <img
                src={icon404}
                style={{ width: "90px", marginBottom: "50px" }}
              />
              <Title style={{ textAlign: "center" }}>
                Vous ne pouvez pas accéder à cette page
              </Title>
              <TextSimpleMoyenBis>
                Pour accéder à votre page de paiement, utilisez le lien qui vous
                a été communiqué par mail.
              </TextSimpleMoyenBis>
              <TextSimpleMoyenBis>
                N'hésitez pas à vérifier dans vos spams.
              </TextSimpleMoyenBis>
              <TextSimpleMoyenBis
                style={{
                  fontWeight: "700",
                }}
              >
                Les équipes Babilou
              </TextSimpleMoyenBis>
              <Sign />
            </BoxRadius>
          </Row>
        </Container>
      </RootHome>
      <FooterComponent />
    </>
  );
}
