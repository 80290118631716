import styled, { createGlobalStyle } from "styled-components";
import signImg from "./images/Signature.svg";

const GlobalStyles = createGlobalStyle`

*{
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
    font-size: 62.5%;

    @media only screen and (max-width: 1200px){
        font-size: 58%;
    }
    @media only screen and (min-width: 1980px){
        font-size: 70%;
    }
}
body{
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    line-height: 1.6;
    font-size: 1.6rem;
    background: #F9F9F9;
    color: red;

}
`;

export const RootHome = styled.div`
  background: white;
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;

  padding-top: 20px;
  padding-bottom: 30px;
  align-items: center;
  @media (max-width: 950px) {
    min-height: calc(100vh - 159px);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 940px;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 950px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const WrapperRadius = styled.div`
  border: 1px solid #c5ced6;
  border-radius: 10px;
  padding: 50px 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const BoxRadius = styled.div`
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left 0px top 20px;
  background-size: 250px;
  border: 1px solid #c5ced6;
  border-radius: 10px;
  padding: 50px 160px;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (max-width: 750px) {
    background: none !important;
    padding: 20px 30px;
  }
`;

export const Label = styled.label`
  display: inline-block;
  font-family: "Montserrat";

  font-size: 15px;
  font-weight: 600;
  color: #535353;
`;

export const Input = styled.input`
  height: 48px;
  width: 100%;
  padding: 8px 16px;
  border: 1px solid #c5ced6;
  border-radius: 4px;
  font-size: 16px;
  color: #424770;
  margin-bottom: 10px;
  transition: box-shadow 0.2s;
  outline: none;

  &:focus {
    border: 2px solid #508ef4;
  }

  &:disabled {
    background-color: #f8f9fa;
    color: #8c9399;
  }
  &::placeholder {
    color: #c2cfd9;
  }
`;

export const TextSimple = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  line-height: 20px;
  font-family: "Montserrat";
  font-weight: 500;
  color: #03378e;
`;
export const TextSimpleMoyen = styled(TextSimple)`
  font-size: 16px;
  font-weight: 600;
`;
export const TextSimpleMoyenBis = styled(TextSimpleMoyen)`
  margin-top: 30px;
  max-width: 540px;
  font-weight: 500;
  text-align: center;
`;

export const BlueText = styled.div`
  font-size: 13px;
  line-height: 1.2;
  font-weight: 700;
  font-family: "Quicksand", arial, helvetica, sans-serif;
  color: #449ce3;

  &:hover {
    color: #ff7e63;
  }

  &::after {
    content: ")";
    margin-left: 0.8rem;
    font-size: 20px;
    font-weight: 600;
  }
  &::before {
    content: "(";
    margin-right: 0.8rem;
    font-size: 20px;
    font-weight: 600;
  }
`;

export const Title = styled.div`
  font-size: 28px;
  font-family: "Quicksand", arial, helvetica, sans-serif;
  font-weight: 700;
  color: #03378e;
  line-height: 27px;
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
`;

export const LinkBabilou = styled.a`
  font-size: 15px;
  line-height: 1.2;
  font-weight: 600;
  margin-left: 10px;
  cursor: pointer;
  font-family: "Montserrat";
  color: #ff7e63;
  text-decoration: underline;

  @media (max-width: 700px) {
    margin-left: 30px;
    margin-top: 5px;
  }
`;

export const Sign = styled.img.attrs({
  src: `${signImg}`,
})`
  width: 115px;
  margin-top: 5px;
  margin-left: 65px;
`;

export default GlobalStyles;
