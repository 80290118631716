import React from "react";

import {
  Container,
  Row,
  TextSimpleMoyenBis,
  Title,
  RootHome,
  BoxRadius,
  Sign,
} from "../../Globalstyles";

import iconCheck from "../../images/Check.svg";
import Logo from "../../images/logo-babilou.png";
import happyFace from "../../images/Happy.svg";

import FooterComponent from "../Footer";

export default function NothingToPayComponent() {
  return (
    <>
      <RootHome>
        <Container style={{ padding: "10px" }}>
          <Row>
            <img
              src={Logo}
              style={{
                width: "160px",
                minWidth: "100px",
                margin: "50px",
              }}
              alt="logo"
            />
          </Row>
          <Row>
            <BoxRadius style={{ backgroundImage: `url(${happyFace})` }}>
              <img
                src={iconCheck}
                style={{ width: "90px", marginBottom: "50px" }}
              />
              <Title style={{ textAlign: "center" }}>
                Vos règlements sont à jour
              </Title>
              <TextSimpleMoyenBis>
                Les règlements de vos factures sont à jour et nous vous en
                remercions
              </TextSimpleMoyenBis>
              <TextSimpleMoyenBis>A bientôt sur notre site.</TextSimpleMoyenBis>
              <TextSimpleMoyenBis style={{ fontWeight: "700" }}>
                Les équipes Babilou
              </TextSimpleMoyenBis>
              <Sign />
            </BoxRadius>
          </Row>
        </Container>
      </RootHome>
      <FooterComponent />
    </>
  );
}
