import styled from "styled-components";
import pictoImg from "../../images/Picto.svg";

export const Footer = styled.div`
  height: 60px;
  font-family: "Quicksand";
  background-color: #02378e;
  color: #fff;
  font-size: 14px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  @media (max-width: 950px) {
    padding: 27px 20px;
    line-height: 150%;
    height: auto;
    ul {
      text-align: center;
    }
  }
`;

export const MyLi = styled.li`
  display: inline;

  margin-right: 5px;
  font-size: 13px;
  font-family: "Montserrat";
  opacity: 0.8;
  font-weight: 600;
  @media (max-width: 950px) {
    display: block;
    opacity: 1;
  }
  a {
    color: white;
    text-decoration: none;
  }
`;

export const Picto = styled.img.attrs({
  src: `${pictoImg}`,
})`
  position: absolute;
  left: 200px;
  width: 59px;
  margin-top: -13px;

  @media (max-width: 950px) {
    left: -25px;
    margin-top: 0px;
    width: 118px;
  }
`;
