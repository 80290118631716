import React, { useState, useEffect } from "react";
import { Collapse } from "react-collapse";
import { AiFillQuestionCircle } from "react-icons/ai";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import Footer from "../../components/Footer";
import Success from "../../components/Success";
import NothingToPay from "../../components/NothingToPay";
import Welcome from "../../components/Welcome";
import StripeCardForm from "../../components/StripeCardForm";
import { Elements } from "@stripe/react-stripe-js";
import { useLocation } from "react-router-dom";
import {
  RootHome,
  Input,
  Label,
  Row,
  Container,
  TextSimple,
  LinkBabilou,
  Title,
  BlueText,
} from "../../Globalstyles";
import {
  HalfDiv,
  MyCurrencyInput,
  ContainerContactUs,
  BtnCollapse,
} from "./Home.style";
import Logo from "../../images/logo-babilou.png";
import Loader from "../../images/loader.gif";
import { loadStripe } from "@stripe/stripe-js";
import API from "../../utils/API";
import ErrorComponent from "../../components/Error";

export default function Home() {
  const [show, setShow] = useState(window.innerWidth > 950 ? true : false);
  const [showButtonCollapse, setShowButtonCollapse] = useState(
    window.innerWidth > 950 ? false : true
  );

  const [appStage, setAppStage] = useState("LOADING");

  //toutes les infos à donner a StripeForm (metadata etc...)
  const [invoiceInformation, setInvoiceInformation] = useState();
  const [promise, setPromise] = useState();

  const search = useLocation().search;

  useEffect(() => {
    /**
     * Fonction pour catcher le changement de taille de la fenetre
     */
    function handleResize() {
      if (window.innerWidth > 950) {
        setShow(true);
        setShowButtonCollapse(false);
      } else {
        setShowButtonCollapse(true);
      }
    }
    // On ajoute le listener resize
    window.addEventListener("resize", handleResize);

    /**
     *
     * @param {*} childPaymentId
     * @returns entity
     */
    async function getInvoiceData(childPaymentId) {
      var res = await API.getInvoiceInformation(childPaymentId);
      if (res.status === 200) {
        if (res.data.currentBalanceAmount < process.env.REACT_APP_MIN_TO_PAY) {
          setAppStage("NOTHING_TO_PAY");
          return "NOTHING_TO_PAY";
        }
        setInvoiceInformation(res.data);
        return res.data.entity;
      } else {
        setAppStage("FAILED");
        return null;
      }
    }

    async function setStripePK(id) {
      let entity = await getInvoiceData(id);
      console.log(" entity ", entity);
      let stripePublicKey;
      switch (entity) {
        case "F001":
          stripePublicKey = process.env.REACT_APP_STRIPE_PK_F001;
          break;
        case "F007":
          stripePublicKey = process.env.REACT_APP_STRIPE_PK_F007;
          break;
        case "F009":
          stripePublicKey = process.env.REACT_APP_STRIPE_PK_F009;
          break;
        case "F028":
          stripePublicKey = process.env.REACT_APP_STRIPE_PK_F028;
          break;
        case "F030":
          stripePublicKey = process.env.REACT_APP_STRIPE_PK_F030;
          break;
        case "F043":
          stripePublicKey = process.env.REACT_APP_STRIPE_PK_F043;
          break;
        case "NOTHING_TO_PAY":
          stripePublicKey = null;
          break;
        default:
          console.log("invalid entity");
          setAppStage("FAILED");
      }

      // If public key is set AND promise not set yet
      if (stripePublicKey && !promise) {
        setPromise(loadStripe(stripePublicKey));
        setAppStage("READY");
      }
    }

    const idUrl = new URLSearchParams(search).get("id");
    if (idUrl) {
      setStripePK(idUrl);
    } else {
      setAppStage("NO_ID");
    }
  }, []);

  /**
   * On passe aux return
   */

  switch (appStage) {
    case "LOADING":
      return (
        <div
          style={{
            display: "flex",
            height: "100vh",
            width: "100vw",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={Loader}
            style={{
              width: "230px",
              minWidth: "100px",
            }}
          />
        </div>
      );
    case "FAILED":
      return <ErrorComponent />;
    case "NOTHING_TO_PAY":
      return <NothingToPay />;
    case "NO_ID":
      return <Welcome />;
    case "SUCCESS":
      return <Success />;
    default:
      break;
  }

  return (
    <>
      <RootHome>
        <Container>
          <Row style={{ justifyContent: "left" }}>
            <img
              src={Logo}
              style={{
                width: "230px",
                minWidth: "100px",
                marginBottom: "50px",
              }}
              alt="logo"
            />
          </Row>
          <Row style={{ justifyContent: "space-between" }}>
            <HalfDiv>
              <form>
                <Title>
                  Votre facture
                  <BtnCollapse
                    style={{
                      display: showButtonCollapse ? "flex" : "none",
                    }}
                    onClick={() => {
                      setShow(!show);
                    }}
                  >
                    {show ? "-" : "+"}
                  </BtnCollapse>
                </Title>
                <Collapse isOpened={show}>
                  <Label>Votre enfant</Label>
                  <Input
                    disabled
                    value={
                      invoiceInformation.child.firstName +
                      " " +
                      invoiceInformation.child.lastName
                    }
                  />

                  <Label>Référence de la facture</Label>
                  <Input disabled value={invoiceInformation.invoiceRef} />

                  <Label>Date d'émission de la facture</Label>
                  <Input
                    disabled
                    value={
                      invoiceInformation.invoiceDate
                        ? moment(
                            invoiceInformation.invoiceDate,
                            "YYYY-MM-DD"
                          ).format("DD/MM/YYYY")
                        : ""
                    }
                  />

                  <Label>Montant de la facture</Label>
                  <MyCurrencyInput
                    suffix=" €"
                    decimalScale={2}
                    disabled
                    value={invoiceInformation.invoiceAmount}
                  />

                  <Label>Montant du solde</Label>
                  <MyCurrencyInput
                    disabled
                    suffix=" €"
                    decimalScale={2}
                    value={invoiceInformation.currentBalanceAmount}
                    style={{ marginBottom: "10px" }}
                  />

                  <BlueText
                    style={{ marginTop: "5px", marginBottom: "20px" }}
                    data-tip="<b style='color:#02378e'>Montant de la facture :</b> montant de la dernière facture mensuelle qui vous a été envoyée <br/><br/>
                    <b style='color:#02378e'>Montant du solde :</b> montant total à régler, correspondant à la somme des factures impayées à date, déduction faite d’éventuels ajustements"
                    data-html={true}
                  >
                    Quelle est la différence entre la facture et le solde ?
                  </BlueText>
                  <ReactTooltip
                    place="top"
                    type="light"
                    effect="solid"
                    className="tooltip"
                    clickable={true}
                  />
                </Collapse>
              </form>
            </HalfDiv>

            <HalfDiv style={{ border: "none" }}>
              <Elements stripe={promise}>
                <StripeCardForm
                  invoiceInformation={invoiceInformation}
                  setAppStage={setAppStage}
                />
              </Elements>
            </HalfDiv>
          </Row>
          <Row>
            <ContainerContactUs>
              <AiFillQuestionCircle
                style={{
                  color: "rgb(126 233 184)",
                  fontSize: "30px",
                  marginRight: "10px",
                  minWidth: "26px",
                }}
              />
              <TextSimple>
                Vous avez un problème technique pour payer sur notre page ?
                <br />
                Ecrivez nous, dans votre{" "}
                <LinkBabilou
                  style={{ display: "contents" }}
                  href="https://www.babilou.fr/parent-auth/login"
                >
                  espace parent
                </LinkBabilou>
                , rubrique "un problème"
              </TextSimple>
            </ContainerContactUs>
          </Row>
        </Container>
      </RootHome>
      <Footer />
    </>
  );
}
