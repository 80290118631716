import styled from "styled-components";
import CurrencyInput from "react-currency-input-field";

export const MyCurrencyInput = styled(CurrencyInput)`
  width: 100%;
  height: 48px;
  padding: 8px 16px;
  border: 1px solid #c5ced6;
  border-radius: 4px;
  font-size: 16px;
  color: #424770;
  margin-bottom: 10px;
  transition: box-shadow 0.2s;
  outline: none;

  &:focus {
    border: 2px solid #508ef4;
  }

  &:disabled {
    background-color: #f8f9fa;
    color: #8c9399;
  }
  &::placeholder {
    color: #c2cfd9;
  }
`;

export const PayButton = styled.button`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3rem;
  width: 100%;
  background-color: #ff7e63;
  color: white;
  margin: 0;
  padding: 15px 9px;
  font-size: 18px;
  font-family: "Montserrat";
  outline: none;
  cursor: pointer;
  border: none;
  margin-top: 30px;
  margin-bottom: 40px;
  font-weight: 600;
  transition: all 0.5s ease;

  &:hover {
    background-color: #ffa895;
    //transform: translateY(-0.5rem) scale(1.02);
    color: #fff;
  }
  &:active {
    transform: translateY(0.5rem);
  }

  &:disabled {
    background-color: #ececec;
    color: #969696;
    cursor: not-allowed;
  }
`;

export const HalfDiv = styled.div`
  width: 45%;
  height: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;

  @media (max-width: 950px) {
    width: 100%;
    max-width: 700px;
    border: none;
    padding: 10px;

    border-bottom: 1px solid #d1d1d1;
  }
`;

export const ContainerContactUs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 20px 20px 20px;
  border-radius: 14px;
  border: 1px solid #c5ced6;
  margin-top: 30px;
  @media (max-width: 700px) {
    border-top: 1px solid #c5ced6;
    border-bottom: none;
    border-left: none;
    border-right: none;
    border-radius: 0px;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0px;
  }
`;

export const BtnCollapse = styled.div`
  font-size: 25px;
  font-weight: bold;
  color: white;
  background-color: #ff7e63;
  border-radius: 100%;
  padding: 10px;
  height: 30px;
  width: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #ffa895;
  }
`;

export const ErrorMessage = styled.div`
  background: #fff4f4;
  padding: 10px;
  border-radius: 4px;
  margin-top: 25px;
  font-size: 16px;
  color: red;
  display: flex;
`;
