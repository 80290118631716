import React, { useState, useEffect } from "react";
import { Oval } from "react-loader-spinner";
import { BiErrorCircle } from "react-icons/bi";

import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Label, Title } from "../../Globalstyles";
import {
  ErrorMessage,
  PayButton,
  MyCurrencyInput,
} from "./StripeCardForm.style";

import API from "../../utils/API";

export default function StripeCardForm(props) {
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [secretPaymentIntent, setSecretPaymentIntent] = useState();
  const [paymentIntentId, setPaymentIntentId] = useState();
  const [lastAmountPaymentIntent, setLastAmountPaymentIntent] = useState("");
  const [amountEntered, setAmountEntered] = useState("");
  const balanceAmount = props.invoiceInformation.currentBalanceAmount;

  const stripe = useStripe();
  const elements = useElements();

  /**
   * UseEffect, fonction executé au chargement de la page uniquement
   * (ou si le props devait être changé -> pas prévu)
   */
  useEffect(() => {
    /**
     * @param {number} amount le montant du paiement à effectuer
     * Création du payment intent Stripe (session de paiement)
     * On sauvegardera le secret reçu en retour dans le state
     */
    async function postPaymentIntent(amount, metadata, entity) {
      var res = await API.createPaymentIntent(amount, metadata, entity);
      if (res.status === 200) {
        setPaymentIntentId(res.data.paymentIntentId);
        setSecretPaymentIntent(res.data.secretToken);
      } else {
        console.log("payment intent failed");
        props.setAppStage("FAILED");
      }
    }

    /**
     * On récupère le props invoice qui contient toute les infos de la facture à payer
     */
    if (props.invoiceInformation) {
      setAmountEntered(balanceAmount.toFixed(2).toString().replace(".", ","));
      setLastAmountPaymentIntent(
        balanceAmount.toFixed(2).toString().replace(".", ",")
      );
      console.log(props.invoiceInformation.childPaymentId);
      postPaymentIntent(
        balanceAmount,
        {
          idBabilog: props.invoiceInformation.child.idBabilog,
          childPaymentId: props.invoiceInformation.childPaymentId,
          invoiceId: props.invoiceInformation.invoiceId,
          invoiceRef: props.invoiceInformation.invoiceRef,
          accountingId: props.invoiceInformation.child.accountingId,
        },
        props.invoiceInformation.entity
      );
    }
  }, []);

  /**
   *
   * @param {*} amount
   * @returns return amount in string well formated
   */
  const formatAmountToNumber = (amount) => {
    if (typeof amount === "string") {
      return Number(amount.replace(",", "."));
    }
  };

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);

    // Test if amount is inferior than the MIN
    if (
      formatAmountToNumber(amountEntered) < process.env.REACT_APP_MIN_TO_PAY
    ) {
      setError(`Vous ne pouvez pas payer moins que 0,50 €`);
      setProcessing(false);
      return;
    }

    // Test if amount is superior than the blanace Amount
    if (formatAmountToNumber(amountEntered) > balanceAmount) {
      setError(
        "Vous ne pouvez pas payer plus que votre solde de " +
          balanceAmount.toFixed(2).toString().replace(".", ",") +
          " €"
      );
      setProcessing(false);
      return;
    }

    if (amountEntered !== lastAmountPaymentIntent) {
      var res = await API.updatePaymentIntent(
        paymentIntentId,
        formatAmountToNumber(amountEntered),
        props.invoiceInformation.entity
      );

      if (res.status === 200) {
        setSecretPaymentIntent(res.data.secretToken);
        setLastAmountPaymentIntent(amountEntered);
      } else {
        // end of process exit function
        console.log("Update intent failed");
        props.setAppStage("FAILED");
        return;
      }
    }
    const payload = await stripe.confirmCardPayment(secretPaymentIntent, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
      },
    });

    if (payload.error) {
      setError(`${payload.error.message}`);
      console.log(payload.error);
      setProcessing(false);
    } else {
      setError(null);
      setProcessing(false);
      props.setAppStage("SUCCESS");
      //console.log(payload);
    }
  };

  /**
   *  Style des inputs Stripe
   */
  var elementClasses = {
    focus: "focused",
    empty: "empty",
    invalid: "invalid",
  };

  var elementStyles = {
    base: {
      color: "#424770",
      fontWeight: 500,
      fontFamily: "Quicksand, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",

      ":focus": {
        color: "#424770",
      },

      "::placeholder": {
        color: "#9BACC8",
      },

      ":focus::placeholder": {
        color: "#CFD7DF",
      },
    },
    invalid: {
      color: "#FA755A",
      ":focus": {
        color: "#FA755A",
      },
      "::placeholder": {
        color: "#FFCCA5",
      },
    },
  };

  /**
   * On passe aux return
   */
  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit} style={{ width: "100%" }}>
        <Title>Le règlement de votre facture</Title>
        <Label>Montant du paiement</Label>

        <MyCurrencyInput
          required
          intlConfig={{ locale: "fr-FR", currency: "EUR" }}
          value={amountEntered}
          maxLength={10}
          allowNegativeValue={false}
          decimalScale={2}
          decimalsLimit={2}
          onValueChange={(value) => {
            setError();
            setAmountEntered(value);
          }}
        />

        <Label>Informations de la carte</Label>
        <div style={{ flexBasis: "100%", width: "100%" }}>
          <CardNumberElement
            id="card-number"
            onChange={handleChange}
            options={{
              showIcon: true,
              style: elementStyles,
              classes: elementClasses,
              preferredNetwork: ["visa", "mastercard"],
            }}
          ></CardNumberElement>
        </div>

        <div style={{ display: "flex", marginTop: "10px" }}>
          <div style={{ flexBasis: "100%", marginRight: "10px" }}>
            <Label>Date de validité</Label>

            <CardExpiryElement
              id="card-expiry"
              onChange={handleChange}
              options={{
                style: elementStyles,
                classes: elementClasses,
              }}
            />
          </div>
          <div style={{ flexBasis: "100%" }}>
            <Label>Cryptogramme</Label>

            <CardCvcElement
              id="card-cvc"
              onChange={handleChange}
              options={{
                placeholder: "123",
                style: elementStyles,
                classes: elementClasses,
              }}
            />
          </div>
        </div>
        {/* Show any error that happens when processing the payment */}
        {error && (
          <ErrorMessage>
            {" "}
            <BiErrorCircle
              style={{
                fontSize: "24px",
                marginRight: "10px",
                minWidth: "26px",
              }}
            />
            {error}
          </ErrorMessage>
        )}

        <PayButton disabled={processing || disabled || error || !amountEntered}>
          {processing ? (
            <Oval color="#FFFFFF" height={20} width={20} />
          ) : amountEntered ? (
            "Payer " + amountEntered + " €"
          ) : (
            "Payer"
          )}
        </PayButton>
      </form>
    </>
  );
}
