import axios from "axios";

const headers = {
  "Content-Type": "application/json",
};
const API = {
  healthy: function () {
    console.log("Call to /healthy");
    return axios
      .get(`${process.env.REACT_APP_BFF_URL}/healthy`, { headers: headers })
      .catch(function (error) {
        console.log(error);
        return error.response;
      });
  },

  createPaymentIntent: function (amount, metadata, entity) {
    //console.log("createPaymentIntent");
    return axios
      .post(
        `${process.env.REACT_APP_BFF_URL}/paymentintent`,
        { amount, metadata, entity },
        { headers: headers }
      )
      .catch(function (error) {
        console.log(error);
        return error.response;
      });
  },

  updatePaymentIntent: function (paymentIntentId, amount, entity) {
    console.log("updatePaymentIntent");

    return axios
      .put(
        `${process.env.REACT_APP_BFF_URL}/paymentintent`,
        { paymentIntentId, amount, entity },
        { headers: headers }
      )
      .catch(function (error) {
        console.log(error);
        return error.response;
      });
  },

  getInvoiceInformation: function (guid) {
    console.log("getInvoiceInformation");
    return axios
      .get(`${process.env.REACT_APP_BFF_URL}/invoiceinformation/${guid}`, {
        headers: headers,
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  },
};

export default API;
