import styled from "styled-components";

export const BabButton = styled.button`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3rem;
  width: 250px;
  background-color: #2cdb8b;
  color: white;
  margin: 0;
  padding: 15px 9px;
  font-size: 18px;
  font-family: "Montserrat";
  outline: none;
  cursor: pointer;
  border: none;
  margin-top: 30px;
  font-weight: 600;
  transition: all 0.5s ease;

  &:hover {
    filter: brightness(1.1);
  }
  &:active {
    transform: translateY(0.5rem);
  }
  a {
    text-decoration: none;
    color: white;
  }

  &:disabled {
    background-color: #ececec;
    color: #969696;
    cursor: not-allowed;
  }
`;
