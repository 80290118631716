import React from "react";

import { Footer, MyLi, Picto } from "./Footer.style";

export default function FooterComponent() {
  return (
    <Footer>
      <Picto />
      <ul>
        <MyLi>
          <a href="https://www.babilou.fr/mentions-legales">Mentions légales</a>
        </MyLi>
        <MyLi style={{ color: "#3888D2" }}>-</MyLi>
        <MyLi>
          <a href="https://www.babilou.fr/donnees-personnelles">
            Politique de confidentialité des données
          </a>
        </MyLi>
        <MyLi style={{ color: "#3888D2" }}>-</MyLi>
        <MyLi>
          <a href="https://www.babilou.fr/aide-contact/famille">Contact</a>
        </MyLi>
      </ul>
    </Footer>
  );
}
