import React from "react";

import {
  Container,
  Row,
  TextSimpleMoyenBis,
  Title,
  RootHome,
  BoxRadius,
} from "../../Globalstyles";
import Footer from "../../components/Footer";
import icon404 from "../../images/404.svg";
import sadFace from "../../images/Sad.svg";

import Logo from "../../images/logo-babilou.png";
import { BabButton } from "./NotFound.style";

export default function NotFound() {
  return (
    <>
      <RootHome>
        <Container style={{ padding: "10px" }}>
          <Row>
            <img
              src={Logo}
              style={{
                width: "160px",
                minWidth: "100px",
                margin: "50px",
              }}
              alt="logo"
            />
          </Row>
          <Row>
            <BoxRadius style={{ backgroundImage: `url(${sadFace})` }}>
              <img
                src={icon404}
                style={{ width: "90px", marginBottom: "50px" }}
              />
              <Title style={{ textAlign: "center" }}>
                Oups... Cette page n'existe pas
              </Title>
              <TextSimpleMoyenBis>
                La page que vous cherchez n'a pas été trouvée.
              </TextSimpleMoyenBis>
              <TextSimpleMoyenBis>
                Mais n'hésitez pas à consulter nos dernières actualités sur
                babilou.fr et découvrir nos meilleures astuces !
              </TextSimpleMoyenBis>
              <BabButton>
                <a href="https://www.babilou.fr/actualites">
                  Retour à l'accueil
                </a>
              </BabButton>
            </BoxRadius>
          </Row>
        </Container>
      </RootHome>
      <Footer />
    </>
  );
}
