import styled from "styled-components";
import CurrencyInput from "react-currency-input-field";

export const MyCurrencyInput = styled(CurrencyInput)`
  width: 100%;
  height: 48px;
  padding: 8px 16px;
  border: 1px solid #c5ced6;
  border-radius: 4px;
  font-size: 16px;
  color: #424770;
  margin-bottom: 10px;
  transition: box-shadow 0.2s;
  outline: none;

  &:focus {
    border: 2px solid #508ef4;
  }

  &:disabled {
    background-color: #f8f9fa;
    color: #8c9399;
  }
  &::placeholder {
    color: #c2cfd9;
  }
`;

export const PayButton = styled.button`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3rem;
  width: 100%;
  background-color: #ff7e63;
  color: white;
  margin: 0;
  padding: 15px 9px;
  font-size: 18px;
  font-family: "Montserrat";
  outline: none;
  cursor: pointer;
  border: none;
  margin-top: 30px;
  margin-bottom: 40px;
  font-weight: 600;
  transition: all 0.5s ease;

  &:hover {
    background-color: #ffa895;
    color: #fff;
  }
  &:active {
    transform: translateY(0.5rem);
  }

  &:disabled {
    background-color: #ffbfb2;
    cursor: not-allowed;
  }
`;

export const ErrorMessage = styled.div`
  background: #fff4f4;
  padding: 10px;
  border-radius: 4px;
  margin-top: 25px;
  font-size: 16px;
  color: red;
  display: flex;
`;
