import React from "react";

import {
  Container,
  Row,
  TextSimpleMoyenBis,
  Title,
  RootHome,
  BoxRadius,
} from "../../Globalstyles";

import FooterComponent from "../Footer";
import icon404 from "../../images/404.svg";
import sadFace from "../../images/Sad.svg";
import Logo from "../../images/logo-babilou.png";

export default function ErrorComponent() {
  return (
    <>
      <RootHome>
        <Container style={{ padding: "10px" }}>
          <Row>
            <img
              src={Logo}
              style={{
                width: "160px",
                minWidth: "100px",
                margin: "50px",
              }}
              alt="logo"
            />
          </Row>
          <Row>
            <BoxRadius style={{ backgroundImage: `url(${sadFace})` }}>
              <img
                src={icon404}
                style={{ width: "90px", marginBottom: "50px" }}
              />
              <Title style={{ textAlign: "center" }}>
                Oups... Erreur technique
              </Title>
              <TextSimpleMoyenBis>
                Nous rencontrons actuellement un problème technique.
              </TextSimpleMoyenBis>
              <TextSimpleMoyenBis>
                Nos équipes font le maximum pour rétablir le service au plus
                vite.
              </TextSimpleMoyenBis>
            </BoxRadius>
          </Row>
        </Container>
      </RootHome>
      <FooterComponent />
    </>
  );
}
